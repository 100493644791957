import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';

import Button from '../Button';
import Heading from '../Heading';

import { parseArrayObj } from '@utils/contentfulFunctions';

const Figure = ({ src, caption, altOrientation, active, alt = '' }) => {
  const variants = {
    active: { opacity: 1, display: 'flex', translateX: 0 },
    hidden: {
      opacity: 0,
      translateX: altOrientation ? -100 : 100,
      display: 'none',
    },
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        variants={variants}
        animate={active ? 'active' : 'hidden'}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 18,
        }}
      >
        <figure
          className={cn(
            'flex flex-col w-full md:max-w-[600px] rounded-[24px] border-[0.5px] overflow-hidden',
            'md:shadow-features',
            altOrientation
              ? 'xl:-translate-x-[40px]'
              : 'xl:-translate-x-[-40px]',
          )}
        >
          <Image
            alt={alt}
            src={src}
            width={1378}
            height={1278}
            loading="eager"
            unoptimized
          />
          <figcaption className="bg-white px-6 pt-5 pb-6 text-gray-600 font-inter text-sm font-medium border-t-[0.5px]">
            {caption}
          </figcaption>
        </figure>
      </motion.div>
    </AnimatePresence>
  );
};

type feature = {
  caption: string | JSX.Element | JSX.Element[];
  icon: string;
  id: number;
  src: string;
  title: string;
};

const BenefitExplainer = ({
  title,
  description,
  icon,
  features,
  altOrientation,
}: {
  altOrientation: boolean;
  description: string | JSX.Element | JSX.Element[];
  features: feature[];
  icon: string;
  title: string;
}) => {
  const parsedFeatures = parseArrayObj(features);
  const [activeFeature, setActiveFeature] = useState(parsedFeatures[0]);

  return (
    <div
      className={cn(
        'flex flex-col md:flex-row items-center justify-between md:gap-16 my-4 md:my-16 w-full',
        altOrientation && 'md:flex-row-reverse',
      )}
    >
      <div className="flex flex-col w-full md:max-w-[400px] lg:max-w-[540px] w-full gap-3">
        <figure className="flex shrink-0 w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] items-center justify-center relative">
          <Image src={icon} alt={`${title} icon`} layout="fill" unoptimized />
        </figure>

        <Heading Tag="h3" size="md" className={cn('sm:mb-4')}>
          {title}
        </Heading>

        <p className={cn('font-inter font-medium text-gray-600', 'sm:text-xl')}>
          {description}
        </p>

        <div className="my-6 py-2 flex md:flex-wrap overflow-x-scroll md:overflow-auto w-full gap-2 scrollbar-none">
          {parsedFeatures.map((item, i) => (
            <Button
              key={i}
              variant={
                activeFeature.buttonText === item.buttonText ? 'black' : 'ghost'
              }
              size="md"
              onClick={() => setActiveFeature(parsedFeatures[i])}
            >
              <figure className="flex shrink-0 w-[12px] h-[12px] sm:w-[16px] sm:h-[16px] items-center justify-center mr-2 relative">
                <SVG
                  height={16}
                  width={16}
                  className="fill-current"
                  src={item.buttonIcon.fields.file.url}
                />
              </figure>

              {item.buttonText}
            </Button>
          ))}
        </div>
      </div>
      <div className="overflow-hidden md:overflow-visible w-full md:h-[574px]">
        {parsedFeatures.map((item, i) => (
          <Figure
            key={i}
            src={item.image.fields.file.url}
            caption={item.text}
            altOrientation={altOrientation}
            active={activeFeature.buttonText === item.buttonText}
            alt={item.image.fields.description}
          />
        ))}
      </div>
    </div>
  );
};

export default BenefitExplainer;
