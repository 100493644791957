import React from 'react';

import BenefitExplainer from '@components/BenefitExplainer';
import CustomerLogos from '@components/CustomerLogos';
import CustomersSection from '@components/CustomersSection';
import CustomHead from '@components/CustomHead';
import EnterpriseGradeSection from '@components/EnterpriseGradeSection';
import FiveCardGridSection from '@components/FiveCardGridSection';
import FooterNew from '@components/FooterNew';
import HeaderNew from '@components/HeaderNew';
import Hero from '@components/Hero';
import HeroBottom from '@components/HeroBottom';
import IntroSection from '@components/IntroSection';
import Section from '@components/Section';
import { revalidate } from '@utils/constants';
import { getHomePage } from '@utils/contentfulClient';
import { parseContentfulData } from '@utils/contentfulFunctions';
import { ContentfulImage } from '@utils/propTypes';

type MetaTags = {
  canonicalUrl?: string;
  description: string;
  image: ContentfulImage;
  title: string;
};

type ParsedDataType = {
  enterpriseSection?: any;
  featuresSection?: any;
  heading?: string;
  logosSection?: {
    heading: string;
    logos: ContentfulImage[];
  };
  metaTags?: MetaTags;
  subHeading?: string;
};

export const getStaticProps = async () => {
  const pageData = await getHomePage();
  const parsedData: ParsedDataType = parseContentfulData(pageData[0]?.fields);

  try {
    return {
      props: {
        data: parsedData,
      },
      revalidate,
    };
  } catch (error) {
    console.log(error);
    return {
      props: {
        err: 'Something went wrong 😕',
        record: null,
      },
    };
  }
};

const Home = ({ data }) => {
  const {
    heading,
    subHeading,
    metaTags,
    logosSection,
    featuresSection,
    enterpriseSection,
  } = data;

  return (
    <>
      <CustomHead
        meta={{
          title: metaTags.title,
          description: metaTags.description,
          image: metaTags.image.file.url,
          url: metaTags.canonicalUrl,
        }}
        openGraph={{
          title: metaTags.title,
          description: metaTags.description,
          image: metaTags.image.file.url,
          url: metaTags.canonicalUrl,
        }}
      />
      <main>
        <HeaderNew />
        <Hero title={heading} description={subHeading} />
        <CustomerLogos
          spacing="pb-6 lg:pb-20 pt-10 lg:pt-20"
          heading={logosSection.heading}
          logos={logosSection.logos}
        />
        <IntroSection
          title={featuresSection.heading}
          description={featuresSection.subHeading}
        />
        <Section>
          {featuresSection.featuresSectionList.map((section, i) => (
            <BenefitExplainer
              key={i}
              title={section.heading}
              description={section.subheading}
              features={section.features}
              icon={section.icon.fields.file.url}
              altOrientation={i % 2 !== 0}
            />
          ))}
        </Section>
        <FiveCardGridSection />
        <EnterpriseGradeSection data={enterpriseSection} />
        <CustomersSection />
        <HeroBottom
          icon={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/popsql_app_icon.png`}
          title="The most powerful workspace for data teams"
          description=""
        />
      </main>
      <FooterNew />
    </>
  );
};

export default Home;
